import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Lang } from "../components/tr";

const NotFoundPage = ({ pageContext: { lang = Lang.cs }, location }) => (
  <Layout lang={lang} location={location}>
    <SEO lang={lang} title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
